<template>
    <Page
        title="Data Loader"
        icon="mdi-file-restore"
        :loading-message="loadingMessage"
        :show-loading="isShowLoading"
        :error-message="errorMessage"
        :show-error="isShowError"
    >
        <v-container>
            <v-row>
                <v-col>
                    <btn @click="runDataFix('alex')" label="Run Alex" small />
                    <btn @click="runDataFix('brian')" label="Run Brian" small />
                    <btn @click="runDataFix('itemCheckAPARProfiles')" label="itemCheckAPARProfiles" small />
                    <btn @click="runDataFix('renameAccountsCompanyId')" label="renameAccountsCompanyId" small />
                    <btn @click="runDataFix('setLegacyCompanyOnCompany')" label="setLegacyCompanyOnCompany" small />
                    <br />To run in live when we do a pull
                    <btn
                        @click="runDataFix('fixApbLinePaymentMethodFromLegacyDocHead')"
                        label="fixApbLinePaymentMethodFromLegacyDocHead"
                        small
                    />
                    <btn @click="runDataFix('createBillLinks')" label="createBillLinks" small />
                    <btn @click="runDataFix('testDates')" label="testDates" small />
                    <btn @click="runDataFix('transactions')" label="DB Transactions" small />
                    <btn @click="runDataFix('patchSKProjectLocation')" label="Patch SK Project Location" small />
                    <btn @click="runDataFix('setPaymentSystemOnCompany')" label="Set Payment System On Company" small />
                    <btn @click="runDataFix('companyChangeFromAccounts')" label="change company from accounts" small />
                    <btn @click="runDataFix('reconcileAPApprove')" label="Reconcile AP Approve" small />
                </v-col>
                <v-col class="text-right">
                    <btn @click="killServer" class="error" small>Kill Server</btn>
                    <btn @click="killWebhook" class="error" small>Kill Webhook</btn>
                    <btn @click="redirectFatapiToAlex" class="error" small>Redirect Fatapi To Alex</btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col> </v-col>
                <v-col class="text-right"> </v-col>
            </v-row>
        </v-container>
        <v-data-table
            :headers="headers"
            :items="dataEntities"
            class="elevation-1"
            hide-default-footer
            :items-per-page="100"
        >
            <template v-slot:item.qbSync="{ item }">
                <btn @click="QBDataSync(false, item.entityName)" small>Test Only</btn>
                <btn @click="QBDataSync(true, item.entityName)" small>Sync!</btn>
                <btn @click="QBDataSync(true, item.entityName, selectedId)" small>Sync One!</btn>
                <btn @click="QBDataSync(true, item.entityName, null, 'first run')" label="First Run!" small />
            </template>
            <template v-slot:item.qbReload="{ item }">
                <btn @click="reloadAllFromQB(item.entityName)" small>Go!</btn>
            </template>
        </v-data-table>
    </Page>
</template>
<script>
import { mapGetters } from 'vuex';
import api from '@/api';

export default {
    data() {
        return {
            testValue: 0,
            loadingMessage: '',
            isShowLoading: false,
            headers: [
                { text: 'Entity Name', value: 'display', align: 'center', sortable: false },
                { text: 'Quickbooks Sync', value: 'qbSync', align: 'center', sortable: false },
                { text: 'Reload from QB', value: 'qbReload', align: 'center', sortable: false },
            ],
            dataEntities: [
                {
                    entityName: 'Account',
                    display: 'Account',
                },
                {
                    entityName: 'Bill',
                    display: 'Bill',
                },
                {
                    entityName: 'Class',
                    display: 'Cost Centre',
                },
                {
                    entityName: 'Company',
                    display: 'Company',
                },
                {
                    entityName: 'Customer',
                    display: 'Customer',
                },
                {
                    entityName: 'Department',
                    display: 'Department',
                },
                {
                    entityName: 'Item',
                    display: 'Item',
                },
                {
                    entityName: 'Invoice',
                    display: 'Invoice',
                },
                {
                    entityName: 'RefundReceipt',
                    display: 'Refund Receipt',
                },
                {
                    entityName: 'TaxCode',
                    display: 'Tax Code',
                },
                {
                    entityName: 'TaxRate',
                    display: 'Tax Rate',
                },
                {
                    entityName: 'Vendor',
                    display: 'Vendor',
                },
                {
                    entityName: 'VendorCredit',
                    display: 'Vendor Credit',
                },
            ],
            batchLogHeaders: [
                { text: 'Created', align: 'center', sortable: true },
                { text: 'Updated', align: 'center', sortable: true },
                { text: 'Request', align: 'center', sortable: true },
                { text: 'Query', align: 'center', sortable: true },
                { text: 'Result', align: 'center', sortable: true },
            ],
            logItems: [],
            errorMessage: '',
            isShowError: false,
            selectedId: '5d428577307adb95ddcd0f79',
        };
    },
    methods: {
        async QBDataSync(doSync, entity, id, match = true) {
            this.loadingMessage = 'Syncing Quickbooks Data';
            this.isShowLoading = true;

            try {
                const loadWhatResult = await api.post(this.$store, 'datasync/syncdata', {
                    from: 'qb',
                    entity,
                    doSync,
                    id,
                    match,
                });
                console.log('result', JSON.stringify(loadWhatResult, null, 2));
            } catch (e) {
                console.log('error - ', e.message);
            }
            this.loadingMessage = '';
            this.isShowLoading = false;

            return entity;
        },
        async reloadAllFromQB(entity) {
            console.log('what?? ', entity);
            this.loadingMessage = `Fetch All ${entity} Data from Quickbooks`;
            this.isShowLoading = true;

            try {
                const loadWhatResult = await api.post(this.$store, 'datasync/reloaddata', {
                    from: 'qb',
                    entity,
                });
                console.log('result', loadWhatResult);
            } catch (e) {
                console.log('error reload', e.message);
            }
            this.loadingMessage = '';
            this.isShowLoading = false;

            return entity;
        },
        async runDataFix(utilCode) {
            this.$store.dispatch('setLoading', utilCode);

            console.log('runDataFix 1');
            try {
                const returnData = await api.post(this.$store, 'datafix', { utilCode });
                console.log(JSON.stringify(returnData.data ? returnData.data : returnData, null, 2));
            } catch (e) {
                console.log('error data fix', e.message);
            }
            this.$store.dispatch('setLoading');
        },
        async killServer() {
            this.loadingMessage = `Killing Server!`;
            this.isShowLoading = true;
            try {
                console.log('Killing Server');
                await api.post(this.$store, 'kill/sendRproKillCommand', {});
            } catch (e) {
                console.log('Server did not respond', e.message);
            }
            this.loadingMessage = '';
            this.isShowLoading = false;
        },
        async killWebhook() {
            this.loadingMessage = `Killing Webhook Server!`;
            this.isShowLoading = true;
            try {
                console.log('Killing Server');
                await api.post(this.$store, 'kill/sendWebhookKillCommand', {});
            } catch (e) {
                console.log('Server did not respond', e.message);
            }
            this.loadingMessage = '';
            this.isShowLoading = false;
        },
        async redirectFatapiToAlex() {
            this.loadingMessage = `Setting Fatapi to point to Alex!`;
            this.isShowLoading = true;
            try {
                await api.post(this.$store, 'command', {
                    commandType: 'setFatapiGlobal',
                    commandValue: { key: 'api-rpro-app.baseUrl', value: 'https://service1.su.rpro.app/' },
                });
            } catch (e) {
                console.log('Server did not respond', e.message);
            }
            this.loadingMessage = '';
            this.isShowLoading = false;
        },
    },
    computed: {
        ...mapGetters([]),
    },
    async created() {
        console.log('created');
    },
};
</script>
<style>
.layout {
    word-break: break-all;
}
</style>
